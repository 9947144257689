import React, { Suspense } from 'react';
import "./loadable.css";

// this will show the animation
const Loader = () => {
  return (
    <div style={{position:"fixed", top:"0", zIndex:"999", width: '100%'}}>
      <div  className="progress">
      <div className="indeterminate"></div>
    </div>
    </div>
  );
};

const Loadable = (Component) => (props) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;